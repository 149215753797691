export const DataMenu = {
  SecondiDiPesce: [
    {
      title: "ACCIUGHE FRITTE",
      price: "12.00",
    },
    {
      title: "ACCIUGHE FRITTE IMPANATE",
      price: "12.00",
    },
    {
      title: "ORATA AI FERRI O BRANZINO AI FERRI",
      price: "15.00",
    },
    {
      title: "FRITTO MISTO DEL GOLFO LIGURE",
      price: "16.00",
    },
    {
      title: "FRITTO DI CALAMARI E GAMBERI",
      price: "15.00",
    },
    {
      title: "PESCE SPADA AI FERRI",
      price: "15.00",
    },
    {
      title: "TAGLIATA DI TONNO",
      price: "16.00",
    },
    {
      title: "GAMBERI ALLA GRILIA",
      price: "16.00",
    },
    {
      title: "ROSA DI BRANZINO SU VELLUTATA DI PORRI E CARCIOFI",
      price: "16.00",
    },
    {
      title: "GRIGLIATA MISTA DI PESCE",
      price: "22.00",
    },
    {
      title: "POLPO ARROSTICCIATO CON PURE DI PATATE AROMATIZZATO AGLI AGRUMI",
      price: "16.00",
    },
    {
      title: "ORATA IN CROSTA DI ZUCCHINE",
      price: "16.00",
    },
  ],
  Antipasti: [
    {
      title: "ACCIUGHE MARINATE ALLA LIGURE",
      price: "10.00",
    },
    {
      title: "POLPO IN CREMA DI PATATE",
      price: "14.00",
    },
    {
      title: "COZZE ALLA TARANTINA",
      price: "12.00",
    },
    {
      title: "IMPEPATA DI COZZE",
      price: "10.00",
    },
    {
      title:
        "MACEDONIA DI MARE AI PROFUMI MEDITERRANEI CON FRUTTI DI MARE E VERDURE",
      price: "16.00",
    },
    {
      title: "OSTRICHE BRETAGNA",
      price: "4.00",
    },
    {
      title: "INSALATA DI SEPPIE ALLA PARMIGIANA",
      price: "15.00",
    },
    {
      title: "TRIS MARE",
      price: "16.00",
    },
    {
      title: "TARTARE DI TONNO FRESCO",
      price: "15.00",
    },
    {
      title: "CAPESANTE GRATINATE",
      price: "12.00",
    },
    {
      title: "CAPRESE",
      price: "12.00",
    },
    {
      title: "MISTO MARE DON CARLOS",
      price: "24.00",
    },
    {
      title: "TUTTO CRUDO",
      price: "30.00",
    },
  ],
  SecondiDiCarne: [
    {
      title: "FILETTO AI FERRI",
      price: "20.00",
    },
    {
      title: "FILETTO AL PEPE VERDE",
      price: "22.00",
    },
    {
      title: "FILETTO ALL'ACETO BALSAMICO E RUCOLA",
      price: "22.00",
    },
    {
      title: "TAGLIATA DI MANZO AI FERRI",
      price: "18.00",
    },
  ],
  PrimiPijati: [
    {
      title: "LINGUINE ALLO SCOGLIO",
      price: "15.00",
    },
    {
      title: "TROFIE ALLA GENOVESE",
      price: "12.00",
    },
    {
      title: "RAVIOLI DI BORRAGINE CON GAMBERI E RUCOLA",
      price: "14.00",
    },
    {
      title: "LASAGNE AL FORNO ALLA BOLOGNESE",
      price: "12.00",
    },
    {
      title: "PASTA VERDE SPADELLATA CON TONNO FRESCO OLIVE E PINOLI",
      price: "15.00",
    },
    {
      title: "PANSOTTI AL SUGO DI NOCI",
      price: "13.00",
    },
    {
      title: "TAGLIOLINI AL NERO DI SEPPIA, BRANZINO E PESTO",
      price: "16.00",
    },
    {
      title: "TROFIE AL ASTICE",
      price: "20.00",
    },
    {
      title: "LINGUINE VONGOLE CALAMARI E POM.PACHINO",
      price: "15.00",
    },
    {
      title: "RISOTTO AI FRUTTI DI MARE (Min 2 persone) Caduno",
      price: "15.00",
    },
    {
      title: "PAELLA ALLA VALENZIANA (Min 2 persone) Caduno",
      price: "18.00",
    },
  ],
  Contori: [
    {
      title: "PATATINE FRITTE",
      price: "6.00",
    },
    {
      title: "VERDURE ALLA GRIGLIA",
      price: "6.00",
    },
    {
      title: "INSALATA MISTA",
      price: "6.00",
    },
  ],
  LePizze: [
    {
      title: "Margherita",
      price: "7.00",
      description: "Pomodoro, Mozzarela",
    },
    {
      title: "Napoli",
      price: "8.00",
      description: "Pomodoro, Mozzarela, Acciughe",
    },
    {
      title: "Marinara",
      price: "6.00",
      description: "Pomodoro, Aglio",
    },
    {
      title: "Prosciutto",
      price: "9.00",
      description: "Pomodoro, Mozzarela, Prosciutto",
    },
    {
      title: "Prosciutto e funghi",
      price: "10.00",
      description: "Pomodoro, Mozzarela, Prosciutto, Funghi",
    },
    {
      title: "Quattro stagioni",
      price: "11.00",
      description: "Pomodoro, Mozzarela, Prosciutto, Carciofi, Funghi",
    },
    {
      title: "Capricciosa",
      price: "12.00",
      description:
        "Pomodoro, Mozzarela, Prosciutto, Funghi, Carciofi, Acciughe, Olive",
    },
    {
      title: "Romana",
      price: "9.00",
      description: "Pomodoro, Mozzarela, Acciughe, Olive",
    },
    {
      title: "Siciliana",
      price: "8.00",
      description: "Pomodoro, Acciughe, Origano, Olive, Capperi",
    },
    {
      title: "Pugliese",
      price: "8.00",
      description: "Pomodoro, Mozzarela, Cipolla",
    },
    {
      title: "Frutti di mare",
      price: "13.00",
      description:
        "Pomodoro, Seppier, Polpo, Cozze, Vongole, Gamberi, Aglio, Prezzemolo",
    },
    {
      title: "Wurstel",
      price: "8.00",
      description: "Pomodoro, Mozzarela, Wurstel",
    },
    {
      title: "Americana",
      price: "11.00",
      description: "Pomodoro, Mozzarela, Wrustel, Patatine",
    },
    {
      title: "Vegetariana",
      price: "10.00",
      description: "Pomodoro, Mozzarela, Verdure grigliate",
    },
    {
      title: "Ortolana",
      price: "11.00",
      description: "Pomodoro, Mozzarela, Verdure grigliate, Rucola, Origano",
    },
    {
      title: "Diavola",
      price: "8.00",
      description: "Pomodoro, Mozzarela, Salame piccante",
    },
    {
      title: "Salsiccia",
      price: "9.00",
      description: "Pomodoro, Mozzarela, Salsiccia",
    },
    {
      title: "Friarielli e salsiccia",
      price: "10.00",
      description: "Pomodoro, Mozzarela, Friarielli, Salsiccia",
    },
    {
      title: "Primavera",
      price: "10.00",
      description: "Pomodoro fresco, Mozzarela di bufala, Basilico",
    },
    {
      title: "Parmigiana",
      price: "9.00",
      description: "Pomodoro, Mozzarela, Melanzane grigliate, Grana",
    },
    {
      title: "Pizzata",
      price: "8.00",
      description: "Pomodoro, Stracchino",
    },
    {
      title: "Calzone",
      price: "9.00",
      description: "Tomato, mozzarella, ham",
    },
    {
      title: "Calzone Farcito",
      price: "10.00",
      description: "Pomodoro, Mozzarela, Prosciutto, Carcofi, Funghi",
    },
    {
      title: "Speciale della casa",
      price: "9.00",
      description: "Pomodoro, Mozzarela, Prosciutto, peperoni",
    },
    {
      title: "Tirolese",
      price: "9.00",
      description: "Pomodoro, Mozzarela, Spec",
    },
    {
      title: "Simpatia",
      price: "10.00",
      description: "Pomodoro, Mozzarela, Spec, Rucola, Grana",
    },
    {
      title: "Gorgonzola",
      price: "8.00",
      description: "Pomodoro, Mozzarela, Gorgonzola",
    },
    {
      title: "Bianca prosciutto e zola",
      price: "9.00",
      description: "Mozzarela, Gorgonzola, prosciutto",
    },
    {
      title: "Biancaneve",
      price: "8.00",
      description: "Mozzarela, stracchino",
    },
    {
      title: "Bianca verde",
      price: "9.00",
      description: "Mozzarela, stracchino, Rucola",
    },
    {
      title: "Genovese",
      price: "10.00",
      description: "Mozzarela, Stracchino, Pesto, Panna",
    },
    {
      title: "Tonno",
      price: "9.00",
      description: "Pomodoro, Mozzarela, Tonno, Origano",
    },
    {
      title: "Tonno e cipolla",
      price: "9.00",
      description: "Pomodoro, Mozzarela, Tonno, Origano, Cipolla",
    },
    {
      title: "Quattro formaggi",
      price: "10.00",
      description: "Mozzarela, Zola, Emmental, Bire, Grana",
    },
    {
      title: "Sarabanda",
      price: "8.00",
      description: "Panna, Speck",
    },
    {
      title: "Occhio di bue",
      price: "8.00",
      description: "Pomodoro, Mozzarela, Uova",
    },
    {
      title: "Bismarck",
      price: "10.00",
      description: "Pomodoro, Mozzarela, Uova, Prosciutto",
    },
    {
      title: "Ai Funghi",
      price: "9.00",
      description: "Pomodoro, Mozzarela, Funghi",
    },
    {
      title: "Barracuda",
      price: "11.00",
      description: "Pomodoro, Mozzarela, Salame piccante, Acciughe, Peperoni",
    },
    {
      title: "Fantasia",
      price: "12.00",
      description: "Pomodoro, Mozzarela, Pancetta, Pesto, Stracchino",
    },
    {
      title: "Delizia",
      price: "9.00",
      description: "Pomodoro, Mozzarela, Crudo, Aglio, Prezzemolo",
    },
    {
      title: "Don Carlos",
      price: "12.00",
      description: "Pomodoro fresco, Mozzarela, Scamoza, Crudo, Gorgonzola",
    },
    {
      title: "Alla Cenere",
      price: "9.00",
      description: "Pate di olive, Mozzarela, Grana, Pana",
    },
    {
      title: "Ai Gamberetti",
      price: "12.00",
      description: "Pomodoro, Mozzarela, Gamberi, Aglio, Prezzemolo",
    },
    {
      title: "Gorgonzola e pera bianca",
      price: "9.00",
      description: "Mozzarela, Gorgonzola, Pera",
    },
    {
      title: "Campana",
      price: "10.00",
      description: "Pomodoro, Mozzarela di bufala, prosciutto",
    },
    {
      title: "Focaccia di Recco",
      price: "15.00",
      description: "Stracchino, Grana, Doppia pasta",
    },
    {
      title: "Barcelona",
      price: "10.00",
      description: "Mozzarela, Gorgonzola, Spinaci",
    },
  ],
  ViniBianchi: [
    {
      title: "MULLER THURGAU Mezzacorona DOC",
      price: "22.00",
      description: "Trentino",
    },
    {
      title: "MULLER THURGAU FRIZZANTE IGT",
      price: "22.00",
      description: "Trentino",
    },
    {
      title: "GEWURZTRAMINER Tolly DOC",
      price: "29.00",
      description: "Alto adige",
    },
    {
      title: "GEWURZTRAMINER Mezzacorona DOC",
      price: "26.00",
      description: "Trentino",
    },
    {
      title: "PINOT GRIGIO Mezzacorona DOC",
      price: "23.00",
      description: "Trentino",
    },
    {
      title: "SAUVIGNON BLANC Mezzacorona DOC",
      price: "23.00",
      description: "Trentino",
    },
    {
      title: "CHARDONNAY Mezzacorona DOC",
      price: "22.00",
      description: "Trentino",
    },
    {
      title: "CODA DI VOLPE TERRADORA IGT",
      price: "23.00",
      description: "Campania",
    },
    {
      title: "GRECO DI TUFO TERRADORA DOCG",
      price: "25.00",
      description: "Campania",
    },
    {
      title: "FIANO DI AVELLINO TERRADORA DOCG",
      price: "24.00",
      description: "Campania",
    },
    {
      title: "LACRYMA CHRISTI TERRADORA DOC",
      price: "24.00",
      description: "Campania",
    },
    {
      title: "FALANGHINA TERRADORA DOC",
      price: "24.00",
      description: "Campania",
    },
    {
      title: "BLANGE CERETTO DOC",
      price: "35.00",
      description: "Piemonte",
    },
    {
      title: "PIAGATO Torre Pernice DOC",
      price: "18.00",
      description: "Liguria",
    },
    {
      title: "PIAGATO Torre Pernice DOC 375 Cl",
      price: "15.00",
      description: "Liguria",
    },
    {
      title: "VERMENTINO DOC LUPI",
      price: "28.00",
      description: "Liguria",
    },
    {
      title: "VERMENTINO DOC Torre Pernici",
      price: "22.00",
      description: "Liguria",
    },
    {
      title: "VERMENTINO Torre Pernice DOC 375 Cl",
      price: "15.00",
      description: "Liguria",
    },
    {
      title: "RIBOLLA GIALLA DOC",
      price: "22.00",
      description: "Veneto",
    },
    {
      title: "GRILLO Feudo aranico",
      price: "22.00",
      description: "Sicilia",
    },
    {
      title: "ARNEIS DOC",
      price: "20.00",
      description: "Piemonte",
    },
    {
      title: 'BRIC AMEL "Marchesi di barolo"',
      price: "18.00",
      description: "Piemonte",
    },
  ],
  ViniRossi: [
    {
      title: 'BARBERA D\'ALBA 2019 DOCG COSTALUNGA "Bersano"',
      price: "23.00",
      description: "Piemonte",
    },
    {
      title: 'BARBERA D\'ALBA DOC RUVEI "Marchesi di barolo"',
      price: "21.00",
      description: "Piemonte",
    },
    {
      title: 'BARBERA D\'ALBA PEIRAGAL 19 DOC "Marchesi di barolo"',
      price: "29.00",
      description: "Piemonte",
    },
    {
      title: 'BAROLO 2017 GOCG TRADIZIONE "Marchesi di barolo"',
      price: "44.00",
      description: "Piemonte",
    },
    {
      title: 'BAROLO DOCG Nirvasco "Bersano"',
      price: "40.00",
      description: "Piemonte",
    },
    {
      title: 'NEBBIOLO D\'ALBA 2019 MICHET DOC "Marchesi di barolo"',
      price: "27.00",
      description: "Piemonte",
    },
    {
      title: 'LANGHE BRIC AMEL "Marchesi di barolo" DOCG',
      price: "19.00",
      description: "Piemonte",
    },
    {
      title: "MERLOT Mezzacorona DOC",
      price: "18.00",
      description: "Trentino",
    },
    {
      title: "BRUNELLO DI MONTALCINO DOCG",
      price: "42.00",
      description: "Toscana",
    },
    {
      title: "CHIANTI CLASSICO RISERVA 2014 DOCG",
      price: "28.00",
      description: "Toscana",
    },
    {
      title: "ROSSO DI MONTALCINO Granducato DOC",
      price: "30.00",
      description: "Toscana",
    },
    {
      title: "TEROLDEGO ROTALIANO DOC. Mezzacorona",
      price: "21.00",
      description: "Trentino",
    },
    {
      title: "SYRAH Feudo Arancio DOC",
      price: "18.00",
      description: "Sicilia",
    },
    {
      title: 'CHIANTI "LE CAPANNE"',
      price: "17.00",
      description: "Toscana",
    },
    {
      title: "NERO D,AVOLA Feudo Arancio DOC",
      price: "19.00",
      description: "Sicilia",
    },
    {
      title: "CABERNET SAUVIGNON Mezzacorona DOC",
      price: "18.00",
      description: "Trentino",
    },
    {
      title: "LANGHE DOC NEBBIOLO Gambarin",
      price: "23.00",
      description: "Piemonte",
    },
    {
      title: "ORMEASCO DI PRONASIO DOP",
      price: "21.00",
      description: "Liguria",
    },
    {
      title: 'BARBARESCO 2017 DOCG TRADIZIONE "Marchesi di barolo"',
      price: "35.00",
      description: "Piemonte",
    },
    {
      title: 'BAROLO DOCG 2017 NIRVASCO "Bersano"',
      price: "42.00",
      description: "Piemonte",
    },
    {
      title: 'NEBIOLO DOC 2019 PAISAN "Bersano"',
      price: "23.00",
      description: "Piemonte",
    },
    {
      title: 'RUCHE DI CASTAGNOLE 2020 DOCG "Bersano"',
      price: "23.00",
      description: "Piemonte",
    },
    {
      title: 'DOCETTO D\'ALBA 2020 COLDELFOSSO "Bersano"',
      price: "23.00",
      description: "Piemonte",
    },
    {
      title: "VINO NOBILE MONTEPULCIANO 2016 DOCG",
      price: "28.00",
      description: "Toscana",
    },
  ],
  ViniRosati: [
    {
      title: "LAGREIN ROSATO DOC",
      description: "Trentino",
      price: "€ 22,00",
    },
    {
      title: "DOLCE ACQUA Gajaudo",
      description: "Liguria",
      price: "€ 25,00",
    },
    {
      title: "ORMEASCO DI PORNASSIO SCIAC-TRA DOC LUPI",
      description: "Liguria",
      price: "€ 26,00",
    },
  ],
  VinoDellaCasa: [
    {
      title: "1 LITRO",
      price: "€ 12,00",
    },
    {
      title: "1/2 LITRO",
      price: "€ 8,00",
    },
    {
      title: "1/4 LITRO",
      price: "€ 5,00",
    },
  ],
  SpumanteChampagne: [
    {
      title: '"COL DE MEZ" VALDOBBIADENE PROSECO SUPERIORE DOCG Extra Dry',
      price: "18.00",
    },
    {
      title: '"CUVÉE SOLICUM" VALDOBBIADENE PROSECO SUPERIORE DOCG Extra Dry',
      price: "21.00",
    },
    {
      title: "BRACHETTO D'ACQUI",
      price: "19.00",
    },
    {
      title: "FRAGOLINO BERLUCCHI",
      price: "15.00",
    },
    {
      title: "FERRARI Spumante Brut Trento DOC",
      price: "25.00",
    },
    {
      title: "BERLUCCHI Franciacorta DOC",
      price: "24.00",
    },
    {
      title: "ROTARI CUVÉE 28",
      price: "23.00",
    },
    {
      title: "CHAMPAGNE CORDON ROUGHE BRUT G.H MUMM",
      price: "50.00",
    },
    {
      title: "CHAMPAGNE PIPER HEIDSIECK BRUT",
      price: "45.00",
    },
  ],
  dolci: [
    {
      title: "Crema alla catalana",
      price: "6.00",
    },
    {
      title: "Tris di crema catalana",
      price: "7.00",
    },
    {
      title: "Tiramisu",
      price: "6.00",
    },
    {
      title: "Panna cotta",
      price: "6.00",
    },
    {
      title: "Meringata con gran marnier",
      price: "8.00",
    },
    {
      title: "Tartufo affogato al caffe",
      price: "8.00",
    },
    {
      title: "Tartufo al pistacchio",
      price: "7.00",
    },
    {
      title: "Tarta coco e nocciola",
      price: "7.00",
    },
    {
      title: "Tri leçe",
      price: "7.00",
    },
    {
      title: "Profiterol",
      price: "7.00",
    },
    {
      title: 'Tarta saint"honore',
      price: "6.00",
    },
    {
      title: "Torroncino",
      price: "6.00",
    },
    {
      title: "Semifreddo allo zabaione",
      price: "6.00",
    },
    {
      title: "Croccante al pistacchio",
      price: "7.00",
    },
    {
      title: "Torta ricotta e pere",
      price: "7.00",
    },
    {
      title: "Sorbetto al limone",
      price: "3.00",
    },
    {
      title: "Passito di pantelleria",
      price: "5.00",
    },
    {
      title: "Crema al Caffè",
      price: "5.00",
    },
    {
      title: "Millefoglie",
      price: "7",
    },
    {
      title: "Tris di cioccolato",
      price: "7.00",
    },
    {
      title: "Creme Caramel",
      price: "6.00",
    },
    {
      title: "Torta yogurt e frutti di bosco",
      price: "7.00",
    },
  ],
  bevande: [
    {
      title: "Birra piccola",
      price: "3.00",
    },
    {
      title: "Birra media",
      price: "5.00",
    },
    {
      title: "Heineken 66 cl.",
      price: "6.00",
    },
    {
      title: "Heineken/Becks 33 cl.",
      price: "4.00",
    },
    {
      title: "Corona/ceres",
      price: "5.00",
    },
    {
      title: "Ichnusa non filtrata 0,50 cl",
      price: "6.00",
    },
    {
      title: "Birra analcolica",
      price: "4.50",
    },
    {
      title: "Ichnusa non filtrata 33 cl",
      price: "5.00",
    },
    {
      title: "Acqua bott. Calizano premium 0.70 cl",
      price: "3.00",
    },
    {
      title: "Coke/Fanta/Sprite",
      price: "3.00",
    },
    {
      title: "The pesca/ Limone",
      price: "3.00",
    },
    {
      title: "Orange soda/ Lemon soda / Chinoto",
      price: "4.00",
    },
    {
      title: "Birra artiginale 019 rossa / bionda",
      price: "5.00",
    },
  ],
  caffeAmari: [
    {
      title: "Caffe",
      price: "2.00",
    },
    {
      title: "Caffe correto / orzo / ginseng / deck",
      price: "2.50",
    },
    {
      title: "Amari nazionali",
      price: "4.00",
    },
    {
      title: "Liquori esteri",
      price: "5.00",
    },
    {
      title: "La gavulin whisky invechiato. 8 anni",
      price: "10.00",
    },
    {
      title: "Hennessy , Martel Cognac",
      price: "8.00",
    },
    {
      title: "Zacapa",
      price: "12.00",
    },
  ],
};
