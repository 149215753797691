import {
  FacebookOutlined,
  InstagramOutlined,
} from "@ant-design/icons/lib/icons";
import React from "react";
import { Logo } from "../../asstes";
import "./Footer.scss";

function Footer() {
  // const businessLocation = {
  //   lat: 44.359058,
  //   lng: 8.574624,
  // };
  const year = new Date().getFullYear();

  // LAT 44.358950, LONG 8.574640

  // AIzaSyCIiqs4PN4Za9wQfrJ74VEXBGXWwbSQHDQ
  return (
    <>
      {/* <div className="mappContainer" style={{ height: 400, display: "flex" }}>
        <Maps />
      </div> */}
      <div className="footer">
        <div className="logofooter">
          <img
            className="img-footer"
            width={300}
            height={200}
            src={Logo}
            alt="logo"
          />
        </div>
        <div className="datafooter">
          Via Corso Matteotti 78 Varazze, Italy <br />
          <a href={"tel:0199399003"} style={{ color: "#715b3e" }}>
            Tel: 019-93 99 003
          </a>{" "}
          |{" "}
          <a href={"tel:3458010664"} style={{ color: "#715b3e" }}>
            Cel: 3458010664
          </a>{" "}
          <br />
          Email:{" "}
          <a style={{ color: "#715b3e" }} href="mailto:edmond1@live.it">
            edmond1@live.it
          </a>
        </div>
        <div className="iconfooter">
          <a
            href="https://www.instagram.com/doncarlos.ristorante/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramOutlined
              style={{ color: "#715b3e", cursor: "pointer" }}
            />
          </a>
          <a
            href="https://www.facebook.com/don.carlos.186"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookOutlined style={{ color: "#715b3e", cursor: "pointer" }} />
          </a>
        </div>
      </div>
      <div className="CopyRights">
        CopyRight &#169; {year} Don Carlos. Powerd By
        <a
          style={{ marginLeft: 5, color: "#715b3e", fontSize: 18 }}
          href="https://www.bm-it-solutions.com/"
          target="_blank"
          rel="noreferrer"
        >
          BM Solutions
        </a>
      </div>
    </>
  );
}

export default Footer;
