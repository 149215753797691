import React from "react";
import {
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img9,
  Img11,
} from "../../asstes";
import "./Slider.scss";

const Slider = () => {
  const images = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img11, Img9];
  const delay = 2500;
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
    // eslint-disable-next-line
  }, [index]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 26}%, 0, 0)` }}
      >
        {images.map((backgroundColor, index) => (
          <>
            <img
              className="slide"
              alt="donCarlos"
              key={index}
              src={backgroundColor}
            />
          </>
        ))}
      </div>

      <div className="slideshowDots">
        {images?.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
