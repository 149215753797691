import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Logo } from "../../asstes";
import { InstagramOutlined, FacebookOutlined } from "@ant-design/icons";
import useWindowWidth from "../../helpers/Helpers";
import "./Header.scss";

const items = [
  {
    label: "RISTORANTE",
    // icon: <MailOutlined />,
    key: "/",
  },
  {
    label: "MENU",
    // icon: <AppstoreOutlined />,
    key: "/menu",
  },
];

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isSmallScreen = useWindowWidth();
  const outlet = useOutlet();

  const [current, setCurrent] = useState(location?.pathname || "/");
  const [scrolled, setScrolled] = useState(false);

  const onClick = (e) => {
    setCurrent(e.key);
    navigate(e?.key);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isSmallScreen ? (
        <div className={`header ${scrolled ? "scrolled" : ""}`}>
          <div className="leftIconHeader">
            <a
              href="https://www.instagram.com/doncarlos.ristorante/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramOutlined
                className="headerIcon"
                style={{ color: "#715b3e", cursor: "pointer" }}
              />
            </a>
          </div>
          <div className="menuContainer">
            {items?.map((el) => {
              return (
                <div
                  onClick={() => onClick(el)}
                  key={el.key}
                  className={`headerMenuItem ${
                    current === el.key ? "menuActive" : ""
                  }`}
                >
                  {el.label}
                </div>
              );
            })}
          </div>
          <div className="rightIconHeader">
            <a
              href="https://www.facebook.com/don.carlos.186"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookOutlined
                className="headerIcon"
                style={{ color: "#715b3e", cursor: "pointer" }}
              />
            </a>
          </div>
        </div>
      ) : (
        <div className={`header ${scrolled ? "scrolled" : ""}`}>
          <div className="logo" onClick={() => navigate("/")}>
            <img src={Logo} alt="logo" width={120} height={70} />
          </div>
          <div className="menuContainer">
            {items?.map((el) => {
              return (
                <div
                  onClick={() => onClick(el)}
                  key={el.key}
                  className={`headerMenuItem ${
                    current === el.key ? "menuActive" : ""
                  }`}
                >
                  {el.label}
                </div>
              );
            })}
          </div>
          <div className="iconHeader">
            <a
              href="https://www.instagram.com/doncarlos.ristorante/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramOutlined
                className="headerIcon"
                style={{ color: "#715b3e", cursor: "pointer" }}
              />
            </a>
            <a
              href="https://www.facebook.com/don.carlos.186"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookOutlined
                className="headerIcon"
                style={{ color: "#715b3e", cursor: "pointer" }}
              />
            </a>
          </div>
        </div>
      )}
      {outlet}
      <Footer />
    </>
  );
};
export default Header;
