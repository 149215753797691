import { FloatButton } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Champagne,
  Img13,
  Img14,
  Img15,
  Img16,
  Img19,
  Img4,
  Img5,
  Pizza,
} from "../../asstes";
import { LoadingComponent } from "../LoadingComponent";
import "./menuPage.scss";

function MenuPage() {
  const navigate = useNavigate();
  const navigateMenu = (name) => {
    navigate("/menuspecifik", { state: name });
  };

  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  const [backgroundLoaded1, setBackgroundLoaded1] = useState(false);

  useEffect(() => {
    const backgroundImage1 = new Image();
    backgroundImage1.src = Img5;

    const handleBackgroundLoaded = () => {
      setBackgroundLoaded1(true);
    };

    const handleBackgroundError = () => {
      setBackgroundLoaded1(false);
    };

    backgroundImage1.addEventListener("load", handleBackgroundLoaded);
    backgroundImage1.addEventListener("error", handleBackgroundError);

    return () => {
      backgroundImage1.removeEventListener("load", handleBackgroundLoaded);
      backgroundImage1.removeEventListener("error", handleBackgroundError);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const backgroundImage = new Image();
      backgroundImage.src = Img13;

      const handleBackgroundLoaded = () => {
        setBackgroundLoaded(true);
      };

      const handleBackgroundError = () => {
        setBackgroundLoaded(false);
      };

      backgroundImage.addEventListener("load", handleBackgroundLoaded);
      backgroundImage.addEventListener("error", handleBackgroundError);

      return () => {
        backgroundImage.removeEventListener("load", handleBackgroundLoaded);
        backgroundImage.removeEventListener("error", handleBackgroundError);
      };
    }, 500);
  }, []);

  if (!backgroundLoaded && !backgroundLoaded1) {
    return <LoadingComponent footer={true} />;
  }

  return (
    <>
      <div class="inner-container"></div>
      <div className="menupage">
        <div className="card" onClick={() => navigateMenu("Antipasti")}>
          <img style={{ objectFit: "cover" }} src={Img13} alt="img5" />
          <div className="clicable-des">ANTIPASTI</div>
        </div>
        <div className="card" onClick={() => navigateMenu("PrimiPijati")}>
          <img style={{ objectFit: "cover" }} src={Img5} alt="img5" />
          <div className="clicable-des">PRIMI PIATTI</div>
        </div>
        <div className="card" onClick={() => navigateMenu("SecondiDiPesce")}>
          <img style={{ objectFit: "cover" }} src={Img19} alt="img5" />
          <div className="clicable-des">SECONDI DI PESCE</div>
        </div>
        <div className="card" onClick={() => navigateMenu("SecondiDiCarne")}>
          <img style={{ objectFit: "cover" }} src={Img16} alt="img5" />
          <div className="clicable-des">SECONDI DI CARNE</div>
        </div>
        <div className="card" onClick={() => navigateMenu("Contori")}>
          <img style={{ objectFit: "cover" }} src={Img4} alt="img5" />
          <div className="clicable-des">CONTORNI</div>
        </div>
        <div className="card" onClick={() => navigateMenu("LePizze")}>
          <img style={{ objectFit: "cover" }} src={Pizza} alt="img5" />
          <div className="clicable-des">LE PIZZE</div>
        </div>
        <div className="card" onClick={() => navigateMenu("dolci")}>
          <img style={{ objectFit: "cover" }} src={Img15} alt="img5" />
          <div className="clicable-des">DOLCI</div>
        </div>
        {/* <div className="card" onClick={() => navigateMenu("bevande")}>
        <img style={{ objectFit: "cover" }} src={Beer} alt="img5" />
        <div className="clicable-des">BEVANDE</div>
      </div> */}
        {/* <div className="card" onClick={() => navigateMenu("caffeAmari")}>
        <img style={{ objectFit: "cover" }} src={Cafe} alt="img5" />
        <div className="clicable-des">CAFFE - AMARI</div>
      </div> */}
        <div className="card" onClick={() => navigateMenu("ViniBianchi")}>
          <img style={{ objectFit: "cover" }} src={Champagne} alt="img5" />
          <div className="clicable-des">VINI BIANCHI</div>
        </div>
        <div className="card" onClick={() => navigateMenu("ViniRossi")}>
          <img style={{ objectFit: "cover" }} src={Img14} alt="img5" />
          <div className="clicable-des">VINI ROSSI</div>
        </div>
        <div className="card" onClick={() => navigateMenu("ViniRosati")}>
          <img style={{ objectFit: "cover" }} src={Img14} alt="img5" />
          <div className="clicable-des">VINI ROSATI</div>
        </div>
        <div className="card" onClick={() => navigateMenu("VinoDellaCasa")}>
          <img style={{ objectFit: "cover" }} src={Img14} alt="img5" />
          <div className="clicable-des">VINO DELLA CASA</div>
        </div>
        <div className="card" onClick={() => navigateMenu("SpumanteChampagne")}>
          <img style={{ objectFit: "cover" }} src={Champagne} alt="img5" />
          <div className="clicable-des">SPUMANTI E CHAMPAGNE</div>
        </div>
        <FloatButton.BackTop />
      </div>
    </>
  );
}

export default MenuPage;
