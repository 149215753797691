import { useEffect, useState } from "react";

const useWindowWidth = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 450);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 450);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isSmallScreen;
};

export default useWindowWidth;
