import { FloatButton } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Img8 } from "../../asstes";
import { LoadingComponent } from "../LoadingComponent";
import Slider from "../Slider/Slider";
import "./homePage.scss";

function HomePage() {
  const [isVideoLoading, setIsVideoLoading] = useState(false);

  function handleVideoLoadStart() {
    setIsVideoLoading(true);
  }

  function handleVideoLoadedData() {
    setTimeout(() => {
      setIsVideoLoading(false);
    }, 500);
  }
  const vidRef = useRef();

  useEffect(() => {
    vidRef?.current?.play();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div class="inner-container"></div>
      <div className="homePage">
        <div className="firstSection">
          {isVideoLoading ? <LoadingComponent /> : null}
          <video
            ref={vidRef}
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
            preLoad="auto"
            loop={true}
            muted={true}
            autoplay={true}
            playsinline={true}
            playsInline={true}
            onLoadStart={handleVideoLoadStart}
            onLoadedData={handleVideoLoadedData}
          >
            <source
              src={require("../../asstes/production.mp4")}
              type="video/mp4"
            />
          </video>
          <div className="TEXT-DES">
            <div>Ristorante Don Carlos Varazze</div>
          </div>
        </div>
        <div className="section-white">
          <div className="promo">
            Nel centro di Varazze sul lungo mare il ristorante Don Carlos si
            presenta con il suo fascino per farti vivere un’esperienza culinaria
            tradizionale, imprescindibile per chiunque si rechi nella città
            Ligure. Con il dehor all’aperto e vista mare fa del “Don Carlos “ il
            luogo preferito per pranzi e cene di piacere e d’affari un luogo
            dove sentirsi in un ambiente familiare.
          </div>
          <img
            className="promoImg"
            src={Img8}
            alt="img8"
            width="50%"
            style={{ borderRadius: 20 }}
          />
        </div>
        <Slider />
        <FloatButton.BackTop />
      </div>
    </>
  );
}

export default HomePage;
