import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import HomePage from "./components/HomePage/HomePage";
import MenuItem from "./components/MenuPage/MenuItem";
import MenuPage from "./components/MenuPage/MenuPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Header />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/menu" element={<MenuPage />} />
          <Route path="/menuspecifik" element={<MenuItem />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
