import React, { useEffect, useRef } from "react";
import "./menuPage.scss";
import { DataMenu } from "../../data/DataMenu";
import { useLocation } from "react-router-dom";
import Slider from "../Slider/Slider";
import { FloatButton } from "antd";

function MenuItem() {
  const location = useLocation();
  const state = location.state || "ANTIPASTI";
  const vidRef = useRef();

  useEffect(() => {
    vidRef.current.play();
  }, []);

  const menu = DataMenu[state];

  const returnName = (name) => {
    switch (name) {
      case "PrimiPijati":
        return "PRIMI PIATTI";
      case "Antipasti":
        return "ANTIPASTI";
      case "SecondiDiPesce":
        return "SECONDI DI PESCE";
      case "SecondiDiCarne":
        return "SECONDI DI CARNE";
      case "Contori":
        return "CONTORNI";
      case "LePizze":
        return "LE PIZZE";
      case "dolci":
        return "DOLCI";
      case "bevande":
        return "BEVANDE";
      case "caffeAmari":
        return "CAFFE - AMARI";
      case "ViniBianchi":
        return "VINI BIANCHI";
      case "ViniRossi":
        return "VINI ROSSI";
      case "ViniRosati":
        return "VINI ROSATI";
      case "VinoDellaCasa":
        return "VINO DELLA CASA";
      case "SpumanteChampagne":
        return "SPUMANTI E CHAMPAGNE";
      default:
        return "ANTIPASTI";
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="menuItem">
      <div className="itembackground">
        <video
          ref={vidRef}
          width="100%"
          height="100%"
          style={{ objectFit: "cover" }}
          preLoad="auto"
          loop={true}
          muted={true}
          autoplay={true}
          playsinline={true}
          playsInline={true}
        >
          <source
            src={require("../../asstes/Menuvideo.mp4")}
            type="video/mp4"
          />
        </video>
      </div>
      <div className="titlemenu">
        <div className="returnName">{returnName(state)}</div>
      </div>
      <div className="menuDes">
        {menu?.map?.((el) => (
          <div className="menu-item" key={el?.title}>
            <div className="title-price-contanier">
              <div className="title-menu">{el?.title}</div>
              <div className="price-menu">{el?.price}</div>
            </div>
            <div className="description-menu">{el?.description}</div>
          </div>
        ))}
      </div>
      <Slider />
      <FloatButton.BackTop />
    </div>
  );
}

export default MenuItem;
