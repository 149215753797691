import React from "react";
import { RotatingSquare } from "react-loader-spinner";

export const LoadingComponent = ({ footer }) => {
  return (
    <div className={footer ? "loadingContainerFooter" : "loadingContainer"}>
      <RotatingSquare
        height="150"
        width="150"
        color="#283238"
        ariaLabel="rotating-square-loading"
        strokeWidth="4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};
